<template>
  <div class="text-center">
    <div class="spinner"></div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.spinner {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translate(-50%, -50%);

  &:after {
    content: " ";
    display: block;
    width: 42px;
    height: 42px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid $dark-grey;
    border-color: $dark-grey transparent $dark-grey transparent;
    animation: spinner-animation 1.2s linear infinite;
  }
}

@keyframes spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
